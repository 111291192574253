import * as $ from "jquery";
import {closePopup} from "../popups";
import {frames} from "./index";


/** Legacy detail popup handler.
 *
 * This is a handler for the old way used in PyRAT to open the detail window in a frame.
 * Eventually it should go away, but in the meantime this should encapsulate the
 * ugliness of keeping the same instance around all the time. It's lipstick on a pig.
 */
export class DetailPopup {

    // Reload the list iFrame after closing the Detail popup.
    public reloadAfterClosing = false;
    public onReloadAfterClosingCallback: () => void;

    public onCloseCallback: () => void;

    private container: JQuery<HTMLElement>;
    private detailFrame: HTMLIFrameElement;

    // If this is true, the very next "load" handling will be skipped.
    // This is Needed for loading e.g. a PDF into the detail window.
    private onceSkipDetailWindowOnLoad = false;

    // Once skip the next close callbacks. This is required for re-opening the detail popup.
    private onceSkipCloseCallbacks = false;

    constructor() {

        this.container = $("#detailwindow");
        this.detailFrame = document.getElementById(frames.FrameName.detail) as HTMLIFrameElement;

        this.container.dialog({
            autoOpen: false,
            closeOnEscape: true,
            closeText: "",
            width: 0,
            height: 0,
            zIndex: 100,
            stack: false, //don't move dialog to top of dialog stack (increase z-index).
            dialogClass: "detail-window-dialog",
            draggable: true,
            resizable: false,
            create: (event) => {
                $(event.target).parent().css("position", "fixed");
            },
            close: () => {

                $(window).off("resize", this.resize);
                this.detailFrame.removeEventListener("load", this.onLoadDetailFrame);
                this.detailFrame.setAttribute("src", "about:blank");
                this.container.dialog("option", {width: 0, height: 0});

                if (this.reloadAfterClosing && !this.onceSkipCloseCallbacks) {
                    frames.reloadListIframe({reloadCallback: this.onReloadAfterClosingCallback});
                }
                if (typeof this.onCloseCallback === "function" && !this.onceSkipCloseCallbacks) {
                    this.onCloseCallback();
                }
                if (this.onceSkipCloseCallbacks) {
                    this.onceSkipCloseCallbacks = false;
                }
            },
            open: () => {
                $(window).on("resize", () => {
                    const position = this.container.dialog("option", "position");

                    //set current position of dialog (top-right) when window is resized
                    this.container.dialog("option", "position", position);
                    this.resize();
                });

                this.detailFrame.addEventListener("load", this.onLoadDetailFrame);
            },
        });

        // set defaults
        this.container.attr({
            _minWidth: 300,
            _minHeight: 150,
            _defaultWidth: 600,
            _defaultHeight: 550,
        });

        // Mobile Safari (iPadOs), does not properly support vertical scrolling for block
        // elements without fixed height. So we scroll the whole div for them.
        if (navigator.userAgent.match(/iPad/i) != null) {
            this.container.css("WebkitOverflowScrolling", "touch");
            this.container.css("overflowY", "scroll");
        }

    }

    /**
     * Set detail window size according to iframe content.
     *
     * Was `resizeDetailWindow()` before.
     *
     * If you need to set a specific width of height for your popup content,
     * set popupHeight and/or popupWidth as attributes to contents body tag.
     * The value can be set as int or with trailing "px"
     * eg: <body class="admin-popup" popupWidth="600">
     */
    public resize = () => {

        const offset = {
            height: 40,
            width: 40,
        };

        const defaultWidth = this.container.attr("_defaultWidth");
        const defaultHeight = this.container.attr("_defaultHeight");

        const minWidth = parseInt(this.container.attr("_minWidth"), 10);
        const minHeight = parseInt(this.container.attr("_minHeight"), 10);
        const maxWidth = window.innerWidth - offset.width;
        const maxHeight = window.innerHeight - offset.height;

        const containerWantsWidth = parseInt(this.container.attr("wantsWidth"), 10) || false;
        const containerWantsHeight = parseInt(this.container.attr("wantsHeight"), 10) || false;

        // get wanted/default dimensions
        let newWidth = containerWantsWidth || defaultWidth;
        let newHeight = containerWantsHeight || this.detailFrame?.contentDocument?.body?.scrollHeight || defaultHeight;

        // compare with max
        newWidth = newWidth > maxWidth ? maxWidth : newWidth;
        newHeight = newHeight > maxHeight ? maxHeight : newHeight;

        // compare with min
        newWidth = newWidth < minWidth ? minWidth : newWidth;
        newHeight = newHeight < minHeight ? minHeight : newHeight;

        this.container.width(newWidth);
        this.container.height(newHeight);
    };

    /**
     * Open a ui dialog detail window.
     *
     * Was open_detail(args, wrappedWithJson)` before.
     *
     * args must either be a URL string to load the detail iframe contents
     * or an object with the following properties:
     *     url:     the url to load in the iframe
     *     method:  the HTTP method used to load the iframe
     *              "GET" (default) or "POST"
     *     params:  optional parameters, an object, whose properties are appended
     *              as a querystring to the url when using GET, or urlencoded in
     *              the POST body
     */
    public open = (args: { url: string; params?: { [key: string]: any }; method?: "GET" | "POST" } | string) => {

        if (this.container.dialog("isOpen")) {
            // if dialog is open, set callback for re-opening
            // since we are about to re-open a dialog, we do not want to execute the close callbacks now
            this.onceSkipCloseCallbacks = true;
            this.container.dialog("close");
            this.open(args);
            return;
        }

        // move the dialog to the end of the body, so it is not hidden by other elements
        this.container.dialog("widget").appendTo("body");

        //set position for detail dialog and open it
        this.container
            .dialog("option", "position", {
                my: "right top+5",
                at: "right-5 top",
                of: window.document,
                collision: "none",
            })
            .dialog("open");

        // set dialog to nearly invisible for resizing, rendering its content and fetch its rendered height
        this.container
            .closest(".ui-dialog")
            .fadeTo(0, 0.01);

        // Load the content.
        // This must happen after the dialog is open, because dialog open
        // moves detailframe to a new place in DOM causing the iframe to reload
        // and load-content-via-post-method can't work.
        if (typeof args === "string") {
            // a URL
            this.detailFrame.src = "about:blank";
            this.detailFrame.src = args;
        } else {
            // object
            if (args.method === "POST") {
                // create and fill a temporary form, set the detailwindow as target, post
                const form = $("<form>").attr({
                    action: args.url,
                    method: "POST",
                    target: "detailframe",
                });
                if (args.params) {
                    for (const k in args.params) {
                        if (Object.prototype.hasOwnProperty.call(args.params, k)) {
                            form.append($("<input>").attr({
                                type: "hidden",
                                name: k,
                                value: args.params[k],
                            }));
                        }
                    }
                }
                $(document.body).append(form);
                form.trigger("submit").remove();
            } else {
                // GET
                let url = args.url;
                if (args.params) {
                    url += "?" + $.param(args.params);
                }
                this.detailFrame.src = "about:blank";
                this.detailFrame.src = url;
            }
        }

        // close other popups
        closePopup("AjaxPopup");
    };

    /**
     * Load a PDF into detail window.
     *
     * Was `openPDFWindow(url)` before.
     *
     * url: the url to load the PDF in the iframe
     */
    public openPDF = (url: string) => {

        // We want to open the PDF in a window of exactly the same size as the calling detail window,
        // so we disable the resizing for the next call. It will enable itself again.
        this.onceSkipDetailWindowOnLoad = true;

        window.top.document.getElementById("detailframe").setAttribute("src", url);
    };

    /**
     * Close a ui dialog detail window and enforce reload of listiframe.
     *
     * Was `close_detail_reload()` before.
     */
    public closeAndReload = () => {
        frames.detailPopup.setReloadAfterClosing();
        this.close();
    };

    /** Close the detail window.
     *
     * Was `close_detail()` before.
     */
    public close = () => {
        // Close detail window.
        this.container.dialog("close");

        // close other popups
        window.top.pyratFrontend.popups.closePopup("AjaxPopup");
    };

    /** Set requirement of re-loading the list frame after the popup is closes **/
    public setReloadAfterClosing = (value = true) => {
        this.reloadAfterClosing = value;
    };

    /** This one is called if dialogs content is loaded/rendered. **/
    private onLoadDetailFrame = () => {

        // Sometimes it's required to skip the onload handler for the next call. Prominent example is loading a
        // PDF in to the detail frame, because this will resize the frame to the wrong size.
        if (this.onceSkipDetailWindowOnLoad) {
            this.onceSkipDetailWindowOnLoad = false;
            return;
        }

        try {
            const iframeWindow = this.detailFrame.contentWindow;
            const iframeDocument = iframeWindow.document;
            const iframeBody = $(iframeDocument.body);
            const detailPopupTitle = iframeBody.find("#popup_title").html();
            // try to fetch forced width and height - see doc-sting of resize()
            // if no specific width is set, use default
            const wantsWidth = parseInt(iframeBody.attr("popupWidth"), 10) + 5 || this.container.attr("_defaultWidth");
            const wantsHeight = parseInt(iframeBody.attr("popupHeight"), 10) + 5 || false;

            // set dialog title
            if (typeof detailPopupTitle !== "undefined") {
                this.container.dialog("option", "title", detailPopupTitle);
            }

            // unset forced width and height of popup body
            iframeBody.attr({width: "", height: ""})
                .css({width: "", height: ""});

            // now only set dialog width and don't care about the height
            // remenber: the dialog is still nearly invisible
            this.container.dialog("option", {
                "width": wantsWidth,
                "height": "auto",
            });

            // now we can try to detect contents height
            const setHeight = wantsHeight || $(iframeDocument).height();

            // and set both for dialog
            this.container.dialog("option", {
                "width": wantsWidth,
                "height": setHeight,
            });

            // store captired values for the resize event
            this.container.attr({
                wantsWidth: wantsWidth,
                wantsHeight: wantsHeight,
            });

            // call the resize function after rendering is done
            window.setTimeout(this.resize, 0);

            // finally show the dialog
            this.container.closest(".ui-dialog").fadeTo(150, 1);

        } catch (ignore) {
            // ignore "Permission denied" error when PDF
            // loaded in iframe (i.e. cage cards)
        }
    };

}
